import React, { useState, useEffect } from 'react';
import './App.css';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import db from './firebase'; // Firebase configuration and Firestore instance

function App() {
  const [formValues, setFormValues] = useState({
    subscribers: '',
    newSubscribers: '',
    avgMonthlyRevenue: '',
    contentSource: 'myself',
    writingTime: '',
    hourlyRate: '',
    postCost: '',
    adsRunning: 'no',
    adsCost: '0',
    monthlyExpenses: '0',
    frequency: 'monthly',
  });

  const [valuation, setValuation] = useState(null);
  const [valuationDetails, setValuationDetails] = useState(''); // Store the detailed breakdown
  const [valuationCount, setValuationCount] = useState(0);
  const [step, setStep] = useState(1); // Step navigation

  // Fetch the valuation counter when the app loads
  useEffect(() => {
    const fetchValuationCount = async () => {
      try {
        const docRef = doc(db, 'stats', 'valuationCounter');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log('Valuation count fetched:', docSnap.data().count);
          setValuationCount(docSnap.data().count);
        } else {
          console.log('No such document! Creating initial counter...');
          // Create the valuationCounter document if it doesn't exist
          await setDoc(doc(db, 'stats', 'valuationCounter'), { count: 0 });
          setValuationCount(0);
        }
      } catch (error) {
        console.error('Error fetching valuation count:', error);
      }
    };

    fetchValuationCount();
  }, []);

  const handleChange = (input) => (e) => {
    setFormValues({ ...formValues, [input]: e.target.value });
  };

  // Update the global valuation counter in Firestore
  const updateValuationCounter = async () => {
    try {
      const docRef = doc(db, 'stats', 'valuationCounter');
      await updateDoc(docRef, {
        count: valuationCount + 1,
      });
      console.log('Valuation count updated successfully');
    } catch (error) {
      console.error('Error updating valuation count:', error);
    }
  };

  const calculateValuation = () => {
    const {
      subscribers,
      newSubscribers,
      avgMonthlyRevenue,
      contentSource,
      writingTime,
      hourlyRate,
      postCost,
      adsRunning,
      adsCost,
      monthlyExpenses,
      frequency,
    } = formValues;

    console.log('Calculating valuation with the following data:', formValues);

    let numPostsPerMonth;
    switch (frequency) {
      case 'daily':
        numPostsPerMonth = 30;
        break;
      case 'weekly':
        numPostsPerMonth = 4;
        break;
      case 'bi-weekly':
        numPostsPerMonth = 2;
        break;
      case 'monthly':
      default:
        numPostsPerMonth = 1;
        break;
    }

    const estimatedNewSubscribers3Years = parseFloat(newSubscribers || 0) * 36;
    const growthRatio = subscribers > 0 ? (estimatedNewSubscribers3Years / subscribers) * 100 : 0;
    const annualRevenueIn3Years = (growthRatio / 100 + 1) * (parseFloat(avgMonthlyRevenue || 0) * 12);

    let writingCost;
    if (contentSource === 'myself') {
      writingCost = numPostsPerMonth * parseFloat(writingTime || 0) * parseFloat(hourlyRate || 0);
    } else {
      writingCost = numPostsPerMonth * parseFloat(postCost || 0);
    }

    const totalCosts = (writingCost + (adsRunning === 'yes' ? parseFloat(adsCost || 0) : 0) + parseFloat(monthlyExpenses || 0)) * 12;
    let expectedProfit = annualRevenueIn3Years - totalCosts;

    if (expectedProfit > 0) {
      expectedProfit /= 1.33;
    } else {
      expectedProfit = 0;
    }

    const finalValuation = Math.max(0, Math.round(expectedProfit * 3)).toLocaleString(); // Add thousands separator here
    setValuation(`$${finalValuation}`);

    const details = `
    - Estimated New Subscribers in 3 years: ${Math.round(estimatedNewSubscribers3Years).toLocaleString()}
    - Subscribers growth over 3 years: ${Math.round(growthRatio)}%
    - Annual revenue in 3 years: $${Math.round(annualRevenueIn3Years).toLocaleString()}
    - Writing cost (annualized): $${Math.round(writingCost * 12).toLocaleString()}
    - Ads & Other expenses (annualized): $${Math.round((adsRunning === 'yes' ? parseFloat(adsCost || 0) : 0) + parseFloat(monthlyExpenses || 0)).toLocaleString() * 12}
    - Total costs (annualized): $${Math.round(totalCosts).toLocaleString()}
    - Expected annual profit (adjusted for time value of money): $${Math.round(expectedProfit).toLocaleString()}
    - Final valuation (Profit * 3): $${finalValuation}
    `;
    setValuationDetails(details);
    console.log('Valuation calculated:', finalValuation);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    calculateValuation();

    // Update the global valuation counter and increment locally
    await updateValuationCounter();

    setValuationCount((prevCount) => prevCount + 1);
    setStep(2);
  };

  return (
    <div className="App">
      <div className="banner">
        <h1>Do you know how much your newsletter is worth?</h1>
        <h2>Calculate in under 2 minutes, for free!</h2>
      </div>

      {/* Google AdSense Ad */}
      <div
        className="adsense"
        dangerouslySetInnerHTML={{
          __html: `
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5950615989586230"
             crossorigin="anonymous"></script>
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-client="ca-pub-5950615989586230"
                 data-ad-slot="2213108757"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
            <script>
                 (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          `,
        }}
      ></div>
        
      {/* Valuation count */}
      <p className="valuations-calculated">Valuations calculated: {valuationCount.toLocaleString()}</p>

      {step === 1 && (
        <Step1
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          values={formValues}
        />
      )}
      {step === 2 && (
        <Step2
          valuation={valuation}
          valuationDetails={valuationDetails} // Pass details to Step 2
          setStep={setStep}
        />
      )}
    </div>
  );
}

export default App;
