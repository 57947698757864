import React from 'react';

function Step1({ handleSubmit, handleChange, values }) {
  const frequencyOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'bi-weekly', label: 'Bi-weekly' },
    { value: 'monthly', label: 'Monthly' },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Number of Subscribers:
        <input
          type="number"
          name="subscribers"
          value={values.subscribers}
          onChange={handleChange('subscribers')}
          min="0"
          required
        />
      </label>

      <label>
        New Subscribers in the Last Month:
        <input
          type="number"
          name="newSubscribers"
          value={values.newSubscribers}
          onChange={handleChange('newSubscribers')}
          min="0"
          required
        />
      </label>

      <label>
        Average Monthly Revenue ($):
        <input
          type="number"
          name="avgMonthlyRevenue"
          value={values.avgMonthlyRevenue}
          onChange={handleChange('avgMonthlyRevenue')}
          min="0"
          required
        />
      </label>

      <label>
        How often do you publish?
        <select
          name="frequency"
          value={values.frequency}
          onChange={handleChange('frequency')}
          required
        >
          {frequencyOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>

      <label>
        Who writes the content?
        <select name="contentSource" value={values.contentSource} onChange={handleChange('contentSource')} required>
          <option value="myself">Myself</option>
          <option value="outsourced">Outsourced</option>
        </select>
      </label>

      {values.contentSource === 'myself' && (
        <>
          <label>
            How much time does it take to write a post (hours)?
            <input
              type="number"
              name="writingTime"
              value={values.writingTime}
              onChange={handleChange('writingTime')}
              min="0"
              required
            />
          </label>

          <label>
            What is a fair writing hourly rate ($):
            <input
              type="number"
              name="hourlyRate"
              value={values.hourlyRate}
              onChange={handleChange('hourlyRate')}
              min="0"
              required
            />
          </label>
        </>
      )}

      {values.contentSource === 'outsourced' && (
        <label>
          What is the cost per post ($)?
          <input
            type="number"
            name="postCost"
            value={values.postCost}
            onChange={handleChange('postCost')}
            min="0"
            required
          />
        </label>
      )}

      <label>
        Do you run ads?
        <select name="adsRunning" value={values.adsRunning} onChange={handleChange('adsRunning')} required>
          <option value="no">No</option>
          <option value="yes">Yes</option>
        </select>
      </label>

      {values.adsRunning === 'yes' && (
        <label>
          Monthly Ad Spend ($):
          <input
            type="number"
            name="adsCost"
            value={values.adsCost}
            onChange={handleChange('adsCost')}
            min="0"
            required
          />
        </label>
      )}

      <label>
        Other Monthly Expenses ($):
        <input
          type="number"
          name="monthlyExpenses"
          value={values.monthlyExpenses}
          onChange={handleChange('monthlyExpenses')}
          min="0"
          required
        />
      </label>

      <button type="submit">Calculate</button>
    </form>
  );
}

export default Step1;