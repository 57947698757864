import React from 'react';

function Step2({ valuation, valuationDetails, setStep }) {
  return (
    <div className="step2-container">
      {/* Valuation Display */}
      <div className="valuation-box">
        <p id="valuation-display">
          Your Estimated Newsletter Valuation: <strong>{valuation}</strong>
        </p>
      </div>

      {/* Valuation Breakdown */}
      <div className="valuation-details-box">
        <h3>How the Valuation Was Calculated:</h3>
        <div className="valuation-details">
          <pre>{valuationDetails}</pre>
        </div>
      </div>

      {/* Support with a Donation */}
      <div className="support-link">
        <a href="https://www.paypal.com/donate/?hosted_button_id=JYAPBENGNYQBU" target="_blank" rel="noopener noreferrer">
          Support with a tip
        </a>
      </div>

      {/* Go Back Button */}
      <button className="button-prev" onClick={() => setStep(1)}>
        Go Back
      </button>
    </div>
  );
}

export default Step2;
